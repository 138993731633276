
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import process from "next/dist/build/webpack/loaders/resolve-url-loader/lib/postcss";
const Audit = ({ t }) => {
    const audit = t('home:audit', {}, { returnObjects: true });
    const AuditItem = ({ img, title, text, url }) => {
        return <li className='audit_item'>
            {url ? <a href={url} target={'_blank'}>
                <img src={`/img/pages/home/audit/${img}.svg`} alt={`$MPEPE ${title} Audit`}/>
            </a> : <img src={`/img/pages/home/audit/${img}.svg`} alt={`$MPEPE ${title} Audit`}/>}
            {text ? <span className='audit_item_text'>{text}</span>
                :
                    null}
    </li>;
    };
    return (<section className='audit' id={'audit'}>
            <div className="container">
                <ul className="audit_items">
                    <AuditItem title={''} img={'audit_lock'} text={audit.secure} url={''}/>
                    <AuditItem title={'Solid Proof'} img={'solid'} text={''} url={process.env.NEXT_PUBLIC_SOLID_PROOF_URL}/>
                    <AuditItem title={'Coinsult'} img={'coinsult'} text={''} url={process.env.NEXT_PUBLIC_COIN_SULT_URL}/>
                </ul>
            </div>
        </section>);
};
export default Audit;

    async function __Next_Translate__getStaticProps__1928b37243e__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Audit/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1928b37243e__ as getStaticProps }
  