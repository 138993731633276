
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
const WinIn = ({ t }) => {
    const win_in = t('home:win_in', {}, { returnObjects: true });
    const WinButton = ({ button, i }) => {
        return <li data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={100 * (i + 1)} className='win_in_button'>
            <Link className='button_whitepaper' prefetch={false} target={'_blank'} href={button.url === 'buy' ? process.env.NEXT_PUBLIC_APP_URL + '/sign-up' : button.url}>
                <button className={button.class_name}>
                    {button.text}
                </button>
            </Link>
        </li>;
    };
    return (<section className='win_in'>
            <div className="container">
                <div className="win_in_wrapper">
                    <h1 className='win_in_title'>
                        <span className='win_in_title_green'>{win_in.title_1}</span>
                        <span className='win_in_title_simple'>{win_in.title_2}</span>
                        <span className='win_in_title_green'>{win_in.title_3}</span>
                        <span className='win_in_title_simple'>{win_in.title_4}</span>
                    </h1>
                    <ul className='win_in_buttons'>
                        {win_in.buttons.map((button, i) => {
            return <WinButton i={i} key={button.text} button={button}/>;
        })}
                    </ul>
                </div>
            </div>
        </section>);
};
export default WinIn;

    async function __Next_Translate__getStaticProps__1928b3723e9__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/WinIn/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1928b3723e9__ as getStaticProps }
  