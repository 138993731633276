
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Accordion from "../../../common/accordion";
const Faqs = ({ t }) => {
    const faqs = t('home:faqs', {}, { returnObjects: true });
    return (<section className='faqs_section' id='faqs'>
            <div className="container">
                <div className="faqs_section_wrapper">
                    <div className='faqs_section_bg'>
                    <h2 className="faqs_section_title">
                        {faqs.title}
                    </h2>
                    <Accordion faqs_list={faqs.data}/>
                    </div>
                </div>
            </div>
        </section>);
};
export default Faqs;

    async function __Next_Translate__getStaticProps__1928b372433__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/FAQ/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1928b372433__ as getStaticProps }
  