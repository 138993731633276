
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useEffect, useState } from 'react';
import MainLayout from "../layouts/main";
import useTranslation from "next-translate/useTranslation";
import Header from "../components/common/Header";
import Loader from "../components/common/Loader";
import axios from "axios";
import Banner from "../components/pages/home/Banner";
import Welcome from "../components/pages/home/Welcome";
import Featured from "../components/pages/home/Featured";
import Introducing from "../components/pages/home/Introducing";
import WinIn from "../components/pages/home/WinIn";
import BigPrize from "../components/pages/home/BigPrize";
import Features from "../components/pages/home/Features";
import BigFeatures from "../components/pages/home/BigFeatures";
import Tokenomics from "../components/pages/home/Tokenomics";
import Audit from "../components/pages/home/Audit";
import RoadMap from "../components/pages/home/RoadMap";
import Team from "../components/pages/home/Team";
import FAQ from "../components/pages/home/FAQ";
import Footer from "../components/common/Footer";
const copyText = (text, className, cb) => {
    let area = document.createElement('textarea');
    area.classList.add('copytextarea');
    area.value = text;
    className ? document.getElementById(className).appendChild(area) : document.body.appendChild(area);
    let copyTextarea = document.querySelector('.copytextarea');
    copyTextarea.focus();
    copyTextarea.select();
    try {
        let successful = document.execCommand('copy');
        successful && cb();
        className ? document.getElementById(className).removeChild(area) : document.body.removeChild(area);
    }
    catch (err) {
        console.log('Oops, unable to copy');
    }
};
export default function Coin({ title, description, canonical }) {
    const { t } = useTranslation();
    const seo = t('common:seo', {}, { returnObjects: true });
    const [isLoading, setIsLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [apiData, setApiData] = useState({
        current_token_price: "0",
        next_token_price: "0",
        percent_tokens: "0",
        percent_usd: "0",
        stage_name: "Stage 1",
        tokens_left_by_stage: "0",
        tokens_sold: "0",
        total_tokens: "0",
        total_usd: "0",
        usd_raised: "0"
    });
    const getData = async () => {
        return await axios.get('/api/get-data', {
            headers: {
                "Content-Type": "application/json"
            }
        });
    };
    useEffect(() => {
        let appLoading = new Promise((resolve, reject) => {
            setTimeout(resolve, 2000, true);
        });
        let apiData = getData().then(data => {
            setApiData(data.data);
        }).catch(() => {
            setApiData({
                current_token_price: "0",
                next_token_price: "0",
                percent_tokens: "0",
                percent_usd: "0",
                stage_name: "Stage 1",
                tokens_left_by_stage: "0",
                tokens_sold: "0",
                total_tokens: "0",
                total_usd: "0",
                usd_raised: "0"
            });
        });
        Promise.all([appLoading, apiData]).then(values => {
            setIsLoading(false);
            setLoaded(true);
        });
    }, []);
    return (<MainLayout loaded={loaded} title={seo.index_page.title} description={seo.index_page.description} keywords={seo.index_page.keywords} page={'index'}>
            <div className='shadow'>
                <Header t={t}/>
                <Banner t={t} apiData={apiData}/>
                <main className='main' style={{ overflowX: 'hidden' }}>
                    <Welcome t={t}/>
                    <Featured t={t}/>
                    <Introducing t={t}/>
                    <WinIn t={t}/>
                    <BigPrize t={t}/>
                    <Features t={t}/>
                    <BigFeatures t={t}/>
                    <Tokenomics copyText={copyText} t={t}/>
                    <Audit t={t}/>
                    <RoadMap t={t}/>
                    <Team t={t}/>
                    <FAQ t={t}/>
                </main>
                <Footer t={t}/>

                <Loader isLoading={isLoading} loaded={loaded}/>
            </div>
        </MainLayout>);
}

    async function __Next_Translate__getStaticProps__1928b3721fe__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1928b3721fe__ as getStaticProps }
  