
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
const Features = ({ t }) => {
    const features = t('home:features', {}, { returnObjects: true });
    const FeatureItem = ({ item, i }) => {
        return <li className='feature_item' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={100 * (i + 1)}>
            <div className='feature_item_title'>
                <img width={item.w} height={134} src={`/img/pages/home/features/${item.icon}.png`} className='features_item_img' alt={`$MPEPE ${item.title}`}/>
                <h3>{item.title}</h3>
            </div>
            <div className='feature_item_text'>
                {item.body.map(el => {
                return <p key={el.key}>
                            {el.text}
                        </p>;
            })}
            </div>
        </li>;
    };
    return (<section className='features' id={'features'}>
            <div className="container">
                <div className="features_wrapper">
                    <ul className='features_items'>
                        {features.map((el, i) => {
            return <FeatureItem i={i} key={el.icon} item={el}/>;
        })}
                    </ul>
                </div>
            </div>
        </section>);
};
export default Features;

    async function __Next_Translate__getStaticProps__1928b3723f2__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Features/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1928b3723f2__ as getStaticProps }
  