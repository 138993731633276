import React, {useState} from 'react';

const AccordionItem = ({item, index, activeIndex, setActiveIndex}) => {

    return <li
        data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay="0"
    >
        <div
            onClick={(e) => {
                if (!activeIndex || activeIndex !== index) {
                    setActiveIndex(index)
                } else {
                    setActiveIndex(null)
                }
            }}
            className={`accordion_item_wrapper ${activeIndex === index ? 'is_active' : ''}`}
        >
            <div className='accordion_item'>
                <h3 className='accordion_item_title'>{item.title}</h3>
                <span className={`accordion_item_icon ${activeIndex ? 'active' : ''}`}> <svg width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g id="_24x24_On_Light_Arrow-Top" transform="translate(24) rotate(90)">
                            <rect id="view-box" width="24" height="24" fill="none"/>
                            <path id="Shape" d="M.22,10.22A.75.75,0,0,0,1.28,11.28l5-5a.75.75,0,0,0,0-1.061l-5-5A.75.75,0,0,0,.22,1.28l4.47,4.47Z" transform="translate(14.75 17.75) rotate(180)" fill="#fff"/>
                        </g>
                    </svg></span>
            </div>
            <div className={`accordion_item_body`}>
                {item.body}
            </div>
        </div>
    </li>
}

const Accordion = ({faqs_list}) => {

    const [activeIndex, setActiveIndex] = useState(null)

    return (
        <div className='accordion_container'>
            <ul className='accordion_data'>
                {faqs_list.map((item) => {
                    return <AccordionItem
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        index={item.index}
                        key={item.index}
                        item={item}
                    />
                })}
            </ul>
        </div>
    );
};

export default Accordion;