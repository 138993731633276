
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import Link from "next/link";
import MainButton from "../../../common/buttons/MainButton";
const Welcome = ({ t }) => {
    const welcome = t('home:welcome', {}, { returnObjects: true });
    const SocialItem = ({ item }) => {
        return <li className={'social_item'}>
            <Link key={item.title} target={'_blank'} className='social_item_link' href={item.title === 'x' ? process.env.NEXT_PUBLIC_X_URL : process.env.NEXT_PUBLIC_TELEGRAM_URL} aria-label={item.title}>
                <img width={28} height={28} src={`/img/pages/home/welcome/${item.title}.svg`} alt={`${item.title} icon`}/>
            </Link>
        </li>;
    };
    return (<section className='welcome' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={200}>
            <div className="container">
                <div className="welcome_wrapper">
                    <ul className="welcome_social_items">
                        {welcome.social.map(item => {
            return <SocialItem key={item.title} item={item}/>;
        })}
                    </ul>
                    <h2 className={'welcome_title'}>{welcome.title}</h2>
                    <p className={'welcome_sub_title'}>{welcome.sub_title}</p>
                    <Link className='welcome_cta' prefetch={false} href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'}>
                        <MainButton text={welcome.cta}/>
                    </Link>
                </div>
            </div>
        </section>);
};
export default Welcome;

    async function __Next_Translate__getStaticProps__1928b3723c5__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Welcome/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1928b3723c5__ as getStaticProps }
  